import { TrainingTaskService } from '@app/core/service/training-task.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from '../service/global.service';

@Injectable()
export class BeginTrainingGuard implements CanActivate {
    constructor(public router: Router, private service: TrainingTaskService,
        private location: Location) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const permissions = GlobalService.getPermission('Page');
        if (permissions['begin training']) {
            return this.service
            .getCurrentTraining()
            .then((e: boolean) => {
                if (e === null || (e['status'] === 'Not Started') || e['status'] === 'In Progress' 
                    || (e['attempt'] === 1 && e['rating'] === 'Unreliable' && 
                    e['status'] === 'Completed')) {
                    return true;
                } else if ((e['status'] === 'Completed' &&
                e['rating'] === "Reliable") || (e['status'] === 'Completed' &&
                e['rating'] === "Unreliable" && e['attempt'] === 2)) {
                    if (GlobalService.isdateToday(e['nextTrainingDate'])) {
                        return true;
                    } else {
                        this.location.back();
                        return false;
                    }
                } else {
                    this.location.back();
                    return false;
                }
            }).catch((error) => {
                console.log(error);
                this.location.back();
                return false;
            });   
        } else {
            this.location.back();
            return false;
        }
    }
}