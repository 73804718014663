import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class HttpClientService {

    constructor(private http: HttpClient) { }

    public request(method, url, body): Observable<any> {
        return this.http.request(method, url, {body: body});
    }

    public get(path: string): Observable<any> {
        return this.http.get(path);
    }

    public post(path: any, params: any): Observable<any> {
        const body = params;
        return this.http.post(path, body);
    }

    public put(path: any, params?: any): Observable<any> {
        const body = params;
        return this.http.put(path, body);
    }

    public delete(path: string): Observable<any> {
        return this.http.delete(path);
    }
}
