import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'no-data',
    templateUrl: 'no-data.component.html'
})

export class NoDataComponent implements OnInit {
    @Input() message = 'No Data Found';
    constructor() { }

    ngOnInit() { }
}