import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-navs',
    templateUrl: 'dashboard-navs.component.html'
})

export class DashboardNavsComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}