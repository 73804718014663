import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class ApiConstant {

    private static BASE_URL = environment.apiUrl

    public static get EMAIL_ALREADY_EXIST(): string {
        return this.BASE_URL + '';
    }

    public static get LOGIN(): string {
        return this.BASE_URL + '/auth/login';
    }

    public static get FIRST_LOGIN(): string {
        return this.BASE_URL + '/auth/first-login';
    }

    public static get SEND_OTP(): string {
        return this.BASE_URL + '/auth/sendOtp';
    }

    public static get VERIFY_OTP(): string {
        return this.BASE_URL + '/auth/verifyOtp';
    }

    public static get RESET_PASSWORD(): string {
        return this.BASE_URL + '/auth/resetPassword';
    }

    public static get COUNTRIES(): string {
        return this.BASE_URL + '/countries';
    }

    public static get SCHOOLS(): string {
        return this.BASE_URL + '/schools';
    }

    public static get REGIONS(): string {
        return this.BASE_URL + '/regions';
    }

    public static get CLASSES (): string {
        return this.BASE_URL + '/classes';
    }

    public static get USERS(): string {
        return this.BASE_URL + '/users';
    }

    public static get USERSNEW(): string {
        return  this.BASE_URL + '/users';
    }

    public static get USERSUPDATENEW(): string {
        return   this.BASE_URL + '/users/updateNew';
    }

    public static get LANGUAGES(): string {
        return this.BASE_URL + '/language';
    }

    public static get PAGE(): string {
        return this.BASE_URL + '/pages';
    }

    public static get ROLES(): string {
        return this.BASE_URL + '/roles';
    }

    public static get USER_REGISTER(): string {
        return this.BASE_URL + '/users/register'
    }

    public static get USER_REGISTERNEW(): string {
        return  this.BASE_URL + '/users/registerNew'
    }

    public static get USER_REGISTER_BULK(): string {
        return this.BASE_URL + '/users/bulk-register'
    }

    public static get PHASES(): string {
        return this.BASE_URL + '/phase';
    }

    public static get MOVEMENTS(): string {
        return this.BASE_URL + '/movements';
    }

    public static get TASKNUMBER(): string {
        return this.BASE_URL + '/taskNumber?queryLimit=all';
    }

    public static get STUDENTS(): string {
        return this.BASE_URL + '/students';
    }

    public static get TASKS(): string {
        return this.BASE_URL + '/tasks';
    }

    public static get TRAININGTASKS(): string {
        return this.BASE_URL + '/training';
    }

    public static get SETTRAININGSTATUS(): string {
        return this.BASE_URL + '/training/status';
    }

    public static get TASK_LIST_FOR_TRAINING(): string {
        return this.BASE_URL + '/tasks/list';
    }

    public static get ASSESSMENT(): string {
        return this.BASE_URL + '/assessments';
    }

    public static get ROLE_PERMISSION(): string {
        return this.BASE_URL + '/permission';
    }

    public static get GET_RANDOM_TASKS(): string {
        return this.BASE_URL + '/training/random';
    }
    
    public static get SAVE_TRAINING(): string {
        return this.BASE_URL + '/results/save';
    }

    public static get DASHBAORD(): string {
        return this.BASE_URL + '/dashboard';
    }

    public static get CURRENT_TRAINING(): string {
        return this.BASE_URL + '/results/current-training';
    }

    public static get TASKLIST_FOR_ANALYSIS():string {
        return this.BASE_URL + '/analyse/tasks';
    }

    public static get STUDENT_FOR_CLASSES(): string {
        return this.BASE_URL + '/analyse/students';
    }

    public static get TAST_CLASSES(): string {
        return this.BASE_URL + '/tasks/';
    }

    public static get TASK_NUMBER_EXIST(): string {
        return this.BASE_URL + '/tasks/exists';
    }

    public static get TRAINING_ATTEMPTS_LIST(): string {
        return this.BASE_URL + '/results';
    }

    public static get STUDENTLIST_FOR_ANALYSIS(): string {
        return this.BASE_URL + '/analyse/classes';
    }

    public static get RESULT_PRACTITITIONER(): string {
        return this.BASE_URL + '/results/user';
    }

    public static get ANALYSE_RESULT(): string {
        return this.BASE_URL + '/analyse';
    }

    public static get THEAM_CONFIG(): string {
        return this.BASE_URL + '/auth/getDomainData';
    }
}
