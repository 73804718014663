import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from '../service/global.service';

@Injectable()
export class EditTaskGuard implements CanActivate {
    constructor(public router: Router, private location: Location) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const permissions = GlobalService.getPermission('Task')
        if (permissions['edit']) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}