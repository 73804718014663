import { Location } from '@angular/common';
import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-not-found',
    template: `
      <h2 style="color: grey; font-weight: bold;opacity: 0.7;text-align: center;" >
        404 - Page not found
      </h2>
      <!-- <h3 style="color: grey; font-weight: bold;
      opacity: 0.7;text-align: center;text-decoration:underline;" ><a (click)="goBack()" >back</a></h3> -->
    `
  })
  export class NotFoundComponent implements OnInit {
    path: string;
  
    constructor(private location: Location) {}
  
    ngOnInit() {
    }

    goBack() {
      this.location.back();
    }
  }