import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavComponent } from './layout/nav/nav.component';

import { AuthModule } from '@modules/auth/auth.module';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { DashboardNavsComponent } from './layout/dashboard-navs/dashboard-navs.component';
import { ToastrModule } from 'ngx-toastr';
import { GlobalErrorHandler } from './core/service/globalErrorHandler';
import { PracNAuthComponent } from './layout/practitioner-no-auth-layout/layout.component';
import { HammerModule} from '@angular/platform-browser';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './core/classes/format-date';
import { WINDOW_PROVIDERS } from './window.providers';
import { HostProviderService } from './core/service/hostprovider.service';

// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    NavComponent,
    DashboardNavsComponent,
    AuthLayoutComponent,
    DashboardLayoutComponent,
    PracNAuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, 
    HammerModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    AuthModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    ChartsModule
  ],
  providers: [
    WINDOW_PROVIDERS, HostProviderService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
