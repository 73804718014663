import { TermsComponent } from './modules/terms/terms.component';
import { CountryEditGuard } from './core/guard/groups/country/country-edit.gaurd';
import { CountryCreateGuard } from './core/guard/groups/country/country-create.gaurd';
import { RegionViewGuard } from './core/guard/groups/region/region-view.gaurd';
import { RegionEditGuard } from './core/guard/groups/region/region-edit.gaurd';
import { RegionCreateGuard } from './core/guard/groups/region/region-create.gaurd';
import { SchoolViewGuard } from './core/guard/groups/school/school-view.gaurd';
import { SchoolEditGuard } from './core/guard/groups/school/school-edit.gaurd';
import { SchoolCreateGuard } from './core/guard/groups/school/school-create.gaurd';
import { ClassCreateGuard } from './core/guard/groups/class/class-create.gaurd';
import { ClassViewGuard } from './core/guard/groups/class/class-view.gaurd';
import { ClassEditGuard } from './core/guard/groups/class/class-edit.gaurd';
import { UserEditGuard } from './core/guard/groups/user/user-edit.gaurd';
import { UserCreateGuard } from './core/guard/groups/user/user-create.gaurd';
import { UserViewGuard } from './core/guard/groups/user/user-view.gaurd';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NoAuthGuard } from '@core/guard/no-auth.guard';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { DashboardNavsComponent } from './layout/dashboard-navs/dashboard-navs.component';
import { AuthGuard } from './core/guard/auth.guard';
import { NotFoundComponent } from './shared/component/not-found-path/notFoundComponent';
import { PracNAuthComponent } from './layout/practitioner-no-auth-layout/layout.component';
import { EditPageGuard } from './core/guard/edit-page.gaurd';
import { BeginTrainingGuard } from './core/guard/begin-training.gaurd';
import { TrainingTaskGuard } from './core/guard/training-task.gaurd';
import { AddTrainingTaskGuard } from './core/guard/add-training-task.gaurd';
import { ListTrainingTaskGuard } from './core/guard/list-training-task.gaurd';
import { EditTrainingTaskGuard } from './core/guard/edit-training-task.gaurd';
import { AddTaskGuard } from './core/guard/task-create.gaurd';
import { EditTaskGuard } from './core/guard/task-edit.gaurd';
import { AssessmentGuard } from './core/guard/assessment.gaurd';
import { CountryViewGuard } from './core/guard/groups/country/country-view.gaurd';
import { ForgotPasswordModule } from './modules/forgot-password/forgot-password.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    children: [
      {
        path: '',
        component: DashboardLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@modules/home/home.module').then(m => m.HomeModule)
          }
        ]
      },
      {
        path: '',
        component: DashboardNavsComponent,
        children: [
          {
            path: 'profile',
            loadChildren: () =>
              import('@modules/profile/user-profile.module').then(m => m.UserProfileModule)
          },
          {
            path: 'about',
            loadChildren: () =>
              import('@modules/about/about.module').then(m => m.AboutModule)
          },
          {
            path: 'assess-it',
            loadChildren: () =>
              import('@modules/assess/main/assess.module').then(
                m => m.AssessModule
              )
          },
          {
            path: 'assess-it/edit-page',
            canActivate: [EditPageGuard],
            loadChildren: () =>
              import('@modules/assess/edit-main/edit-main-assess.module').then(
                m => m.EditMainAssessModule
              )
          },
          {
            path: 'assess-it/:id',
            loadChildren: () =>
              import('@modules/assess/activity-card/activity-card.module').then(
                m => m.ActivityCardModule
              )
          },
          {
            path: 'assess-it/:id/assessment',
            canActivate: [AssessmentGuard],
            loadChildren: () =>
              import('@modules/assess/begin-assessment/begin-assessment.module').then(
                m => m.BeginAssesModule
              )
          },
          {
            path: 'groups',
            loadChildren: () =>
              import('@modules/groups/groups.module').then(m => m.GroupsModule)
          },
          {
            path: 'training',
            loadChildren: () =>
              import('@modules/training/main/training.module').then(
                m => m.TrainingModule
              )
          },
          {
            path: 'training/begin',
            canActivate: [BeginTrainingGuard],
            loadChildren: () =>
              import('@modules/training/begin-training/begin-training.module').then(
                m => m.BeginTrainingModule
              )
          },
          {
            path: 'training/status',
            // canActivate: [BeginTrainingGuard],
            loadChildren: () =>
              import('@modules/training/my-training/my-training.module').then(
                m => m.MyTrainingModule
              )
          },
          {
            path: 'play-it',
            loadChildren: () =>
              import('@modules/task/main/task.module').then(m => m.TaskModule)
          },
          {
            path: 'play-it/edit-page',
            canActivate: [EditPageGuard],
            loadChildren: () =>
              import('@modules/task/edit-main/edit-main-task.module').then(
                m => m.EditMainTaskModule
              )
          },
          {
            path: 'play-it/add',
            canActivate: [AddTaskGuard],
            loadChildren: () =>
              import('@modules/task/add/task-add.module').then(
                m => m.TaskAddModule
              )
          },
          {
            path: 'play-it/:id',
            loadChildren: () =>
              import('@modules/task/detail/task-detail.module').then(m => m.TaskDetailModule)
          },
          {
            path: 'play-it/:id/edit',
            canActivate: [EditTaskGuard],
            loadChildren: () =>
              import('@modules/task/add/task-add.module').then(m => m.TaskAddModule)
          },
          {
            path: 'play-it/:id/assess',
            canActivate: [AssessmentGuard],
            loadChildren: () =>
              import('@modules/assess/begin-assessment/begin-assessment.module').then(m => m.BeginAssesModule)
          },
          {
            path: 'add-class',
            canActivate: [ClassCreateGuard],
            loadChildren: () =>
              import('@modules/class/add/class-add.module').then(
                m => m.ClassAddModule
              )
          },
          {
            path: 'groups/classes/add',
            loadChildren: () =>
              import('@modules/class/add-participant/class-add.module').then(
                m => m.ClassAddModule
              )
          },
          {
            path: 'groups/classes/edit',
            loadChildren: () =>
              import('@modules/class/add-participant/class-add.module').then(
                m => m.ClassAddModule
              )
          },
          {
            path: 'class/:id/edit',
            canActivate: [ClassEditGuard],
            loadChildren: () =>
              import('@modules/class/add/class-add.module').then(
                m => m.ClassAddModule
              )
          },
          {
            path: 'class/:id',
            canActivate: [ClassViewGuard],
            loadChildren: () =>
              import('@modules/class/detail/class-detail.module').then(
                m => m.ClassDetailModule
              )
          },
          {
            path: 'users/:id',
            canActivate: [UserViewGuard],
            loadChildren: () =>
              import('@modules/users/single-detail/user-detail.module').then(
                m => m.UserDetailModule
              )
          },
          {
            // users/:id/
            path: 'participant/:studentId',
            loadChildren: () =>
              import('@modules/student/student.module').then(
                m => m.StudentDetailModule
              )
          },
          {
            path: 'add-user',
            canActivate: [UserCreateGuard],
            loadChildren: () =>
              import('@modules/users/add/user-add.module').then(
                m => m.UserAddModule
              )
          },
          {
            path: 'users/:id/edit',
            canActivate: [UserEditGuard],
            loadChildren: () =>
              import('@modules/users/single-detail/user-detail.module').then(
                m => m.UserDetailModule
              )
          },
          {
            path: 'company/:id',
            canActivate: [UserViewGuard],
            loadChildren: () =>
              import('@modules/company/company-detail/company-detail.module').then(
                m => m.CompanyDetailModule
              )
          },

          {
            path: 'add-company',
            canActivate: [UserCreateGuard],
            loadChildren: () =>
              import('@modules/company/add/company-add.module').then(
                m => m.CompanyAddModule
              )
          },
          {
            path: 'company/:id/edit',
            canActivate: [UserEditGuard],
            loadChildren: () =>
              import('@modules/company/company-detail/company-detail.module').then(
                m => m.CompanyDetailModule
              )
          },
          {
            path: 'add-school',
            canActivate: [SchoolCreateGuard],
            loadChildren: () =>
              import('@modules/school/add/school-add.module').then(
                m => m.SchoolAddModule
              )
          },
          {
            path: 'school/:id/edit',
            canActivate: [SchoolEditGuard],
            loadChildren: () =>
              import('@modules/school/add/school-add.module').then(
                m => m.SchoolAddModule
              )
          },
          {
            path: 'school/:id',
            canActivate: [SchoolViewGuard],
            loadChildren: () =>
              import('@modules/school/details/school-details.module').then(
                m => m.SchoolDetailModule
              )
          },
          {
            path: 'add-region',
            canActivate: [RegionCreateGuard],
            loadChildren: () =>
              import('@modules/regions/add/region-add.module').then(
                m => m.RegionAddModule
              )
          },
          {
            path: 'region/:id/edit',
            canActivate: [RegionEditGuard],
            loadChildren: () =>
              import('@modules/regions/add/region-add.module').then(
                m => m.RegionAddModule
              )
          },
          {
            path: 'region/:id',
            canActivate: [RegionViewGuard],
            loadChildren: () =>
              import('@modules/regions/details/region-details.module').then(
                m => m.RegionDetailModule
              )
          },
          {
            path: 'add-country',
            canActivate: [CountryCreateGuard],
            loadChildren: () =>
              import('@modules/country/add/country-add.module').then(
                m => m.CountryAddModule
              )
          },
          {
            path: 'country/:id/edit',
            canActivate: [CountryEditGuard],
            loadChildren: () =>
              import('@modules/country/add/country-add.module').then(
                m => m.CountryAddModule
              )
          },
          {
            path: 'country/:id',
            canActivate: [CountryViewGuard],
            loadChildren: () =>
              import('@modules/country/details/country-details.module').then(
                m => m.CountryDetailModule
              )
          },
          {
            path: 'training/training-tasks',
            canActivate: [TrainingTaskGuard],
            loadChildren: () =>
              import(
                '@modules/training/view-training-tasks/training-task.module'
              ).then(m => m.TrainingTaskViewModule)
          },
          {
            path: 'training/training-tasks/results/:id',
            loadChildren: () =>
              import('@modules/training/results/result.module').then(
                m => m.PractionerTRModule
              )
          },
          {
            path: 'training/training-tasks/add',
            canActivate: [AddTrainingTaskGuard],
            loadChildren: () =>
              import(
                '@modules/training/add-training-task/add-training-task.module'
              ).then(m => m.AddTrainingTaskViewModule)
          },
          {
            path: 'training/training-tasks/:id',
            canActivate: [ListTrainingTaskGuard],
            loadChildren: () => import('@modules/training/training-task-detail/training-task-detail.module'
            ).then(m => m.TrainingTaskDetailModule)
          },
          {
            path: 'training/training-tasks/:id/edit',
            canActivate: [EditTrainingTaskGuard],
            loadChildren: () =>
              import(
                '@modules/training/add-training-task/add-training-task.module'
              ).then(m => m.AddTrainingTaskViewModule)
          },
          {
            path: 'training/edit-page',
            canActivate: [EditPageGuard],
            loadChildren: () =>
              import(
                '@modules/training/edit-main/edit-main-training.module'
              ).then(m => m.EditMainTrainingModule)
          },
          {
            path: 'analyse-it',
            loadChildren: () =>
              import('@modules/analyze/analyze.module').then(
                m => m.AnalyzeModule
              )
          },
          {
            path: 'analyse-result',
            loadChildren: () =>
              import('@modules/analyze-result/analyze-result.module').then(
                m => m.AnalyzeResultModule
              )
          }
        ]
      }
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'index',
    component: PracNAuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'index/terms',
    component: TermsComponent,
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('@modules/terms/terms.module').then(m => m.TermsModule)
  },

  {
    path: 'forgot-password',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('@modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },

  {
    path: 'reset-password/:id',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('@modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },

  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }