<div class="myform form pb-4">
  <div class="logo mb-3">
    <img class="mx-auto d-block mb-4" src="../../../../../assets/images/logoBlack.png">
    <div class="col-md-12 p-0 text-center">
      <h1>CREATE YOUR ACCOUNT
        TO BEGIN TRAINING</h1>
    </div>
  </div>
  <form (ngSubmit)="submit()" [formGroup]="myForm" >
    <div class="form-group">
      <label>Name</label>
      <input type="text" name="name" class="form-control" [value]="firstLoginObj['name']" readonly>
    </div>
    <div class="form-group">
      <label>Email</label>
      <input type="text" name="email" class="form-control" [value]="firstLoginObj['email']" readonly>
    </div>
    <div class="form-group">
      <label>Password</label>
      <input type="password" name="password" formControlName="password" class="form-control">
      <app-control-messages [control]="f['password']"></app-control-messages>
    </div>
    <div class="col-md-12 p-0 mt-4 text-center ">
      <button type="submit" class=" btn btn-block mybtn btn-primary">CREATE ACCOUNT</button>
    </div>
    <div class="form-group mb-0">
      <p class="text-center">Already have an account? <a [routerLink]="['/auth/login']">Sign In here!</a></p>
    </div>
  </form>
</div>
