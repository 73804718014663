import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ToastrCustomService {
    constructor(private toastr: ToastrService) { }
    
    showSuccess(mess1, mess2?, time=3000) {
        this.toastr.success(mess1, mess2, {
            timeOut: time,
        });
    }

    showSuccessBottom(mess1, mess2?) {
        this.toastr.success(mess1, mess2, {
            timeOut: 1000,
            positionClass: 'toast-bottom-center'
        });
    }

    showError(mess1, mess2?) {
        this.toastr.error(mess1, mess2, {
            timeOut: 10000,
            closeButton: true
        });
    }

    showWarning(mess1, mess2?) {
        this.toastr.warning(mess1, mess2, {
            timeOut: 3000,
        });
    }

    showInfo(mess) {
        this.toastr.info(mess, '', {
            timeOut: 3000
        });
    }
}