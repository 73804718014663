import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/service/auth.service';
import { StorageService } from '@app/core/service/storage.service';
import { ToastrCustomService } from '@app/core/service/toastr.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  error: string = '';
  isLoading: boolean;
  myForm: FormGroup;
  private sub: Subscription;
  token: string;
  isPrac: boolean;
  firstLoginObj = {
    name: '',
    email: '',
    token: ''
  };
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrCustomService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.token = params['token'];
        const role = params['role'];
        if (this.token && role) {
          this.isPrac = (role === 'practitioner') ? true : false;
          const decodedToken: Object = this.authService.getDecodedAccessToken(this.token);
          if (decodedToken.hasOwnProperty('firstName') &&
            decodedToken.hasOwnProperty('lastName') &&
            decodedToken.hasOwnProperty('email')) {
            const name = decodedToken['firstName'] + ' ' + decodedToken['lastName'];
            this.firstLoginObj = {
              token: this.token,
              name: name,
              email: decodedToken['email']
            };
            StorageService.setItem('firstLoginToken', JSON.stringify(this.firstLoginObj));
            // console.log(this.firstLoginObj);
            if (!this.isPrac) {
              this.buildForm();
            } else {
              this.router.navigate(['/index'], { queryParams: { page: 2 } });
            }
          } else {
            this.toastr.showError('Please try with the Authentic link', 'UnAuthorized');
            this.router.navigate(['/auth/login']);
          }
        } else {
          this.toastr.showError('Invalid Session');
          this.router.navigate(['/auth/login']);
        }
      });
  }

  async submit() {
    this.myForm.markAllAsTouched();
    if (this.myForm.valid) {
      try {
        await this.authService.firstLogin(this.myForm.value);
        this.router.navigate(['/dashboard']);
        StorageService.removeItem('firstLoginToken');
      } catch (error) {
        console.log(error);
      }
    }
  }

  buildForm() {
    this.myForm = this.fb.group({
      token: [this.token, Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.myForm.controls;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
