import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate(): boolean {
    if (AuthService._token) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
