import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NAVITEMS } from '@app/core/constants/static';
import { AuthService } from '@app/core/service/auth.service';
import { PageService } from '@app/core/service/page.service';
import { StorageService } from '@app/core/service/storage.service';
import { validateEmail } from '@app/core/validators/email-validator';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { GlobalService } from '@app/core/service/global.service';
import { ThemeService } from '@app/core/service/theme.service';
import { environment } from '@env';
declare var $: any
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class PracNAuthComponent implements OnInit, OnDestroy {
    isIndexPage = false;
    navItems = NAVITEMS;
    isPractioner = false;
    btnClass: any;
    about: any;
    sub: Subscription
    page: any;
    error: string = '';
    isLoading: boolean;
    loginForm: FormGroup;
    accept: number;


    assetUrl= environment.imageUrl;
    logImage:string="../../../assets/images/logoBlackW.png";
    constructor(private pageService: PageService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private themeService: ThemeService,
        private spinner: NgxSpinnerService) {
        this.btnClass = 'main-btn';
        this.buildFormForFirstLogin();
        this.buildForm();
        this.getAboutPage();
    }

    loading: boolean = true;
    modalOpen(page) {
        if (page === 1) {
            $('#registerModal').modal('hide');
            $('#loginModal').modal('show');
        } else if (page === 2) {
            $('#loginModal').modal('hide');
            this.firstLoginObj = JSON.parse(StorageService.getItem('firstLoginToken'));
            if (!_.isEmpty(this.firstLoginObj) && !GlobalService.emptyObject(this.firstLoginObj)) {
                $('#registerModal').modal('show');
                this.myForm.controls['name'].setValue(this.firstLoginObj['name']);
                this.myForm.controls['email'].setValue(this.firstLoginObj['email']);
                this.myForm.controls['token'].setValue(this.firstLoginObj['token']);
            } 
            else {
                // this.toastr.showError('Link Expired');
                this.router.navigate(['/index']);
            }
        } else if (page === 3) {
            $('#termsAndConditionModal').modal('hide');
            $('#termsAndConditionModal').modal('show');
        }
    }

    closeModal(page) {
        if (page === 1) {
            $('#loginModal').modal('hide');
        } else if (page === 2) {
            $('#registerModal').modal('hide');
        } else if (page === 3) {
            $('#termsAndConditionModal').modal('hide');
        }
    }

    get f() {
        return this.loginForm.controls;
    }

    get fR() {
        return this.myForm.controls;
    }

    async login() {
        this.error = '';
        if (this.loginForm.valid) {
            this.spinner.show();
            this.isLoading = true;
            const credentials = this.loginForm.value;
            try {
                StorageService.setItem('ip', '192.168.44.55');
                const user = await this.authService.login(credentials);
                this.closeModal(1);
                console.log(user);
                this.isLoading = false;
                this.router.navigate(['/dashboard']);
            } catch (error) {
                console.log(error);
                // this.closeModal();
                this.error = error.msg || '';
                this.spinner.hide();
            }
        }
    }

    async submit() {
        if (this.myForm.valid) {
            try {
              await this.authService.firstLogin({token: this.firstLoginObj['token'], password: this.myForm.value.password});
              this.closeModal(2);
              StorageService.removeItem('firstLoginToken');
              this.router.navigate(['/dashboard']);
            } catch (error) {
                if (error)
              console.log(error);
              this.closeModal(2);
            }
        }
    }

    private buildForm(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [
                Validators.required,
                validateEmail
            ]],
            password: ['', Validators.required]
        });
    }

    myForm: FormGroup;
    firstLoginObj = {name: '',email: '',token: ''};
    private buildFormForFirstLogin() {
        
        this.myForm = this.formBuilder.group({
            name: [''],
            email: [''],
            token: ['', Validators.required],
            password: ['', Validators.required]
        });
        // console.log(this.myForm.value);
        this.loading = false;
    }

    async getAboutPage() {
        try {
            const response = await this.pageService.getSingle('About');
            // console.log('about : ', response);
            this.about = response;
            this.about['video'] = (this.about['fileUrl'].includes('.mp4') ||
            this.about['fileUrl'].includes('.ogg') ||
            this.about['fileUrl'].includes('.webm')) ? true : false;
        } catch (error) {

        }
    }
    ngOnInit(): void {
        this.sub = this.route
            .queryParams
            .subscribe(params => {
                this.page = +params['page'] || 1;
                this.modalOpen(this.page);
            });

            this.setTheamConfig();
    }

    redirectTo(page) {
        this.page = page;
        this.modalOpen(this.page);
    }

    // setting theme 

    setTheamConfig() {
        this.sub = this.themeService.globalConfig.subscribe((res: any) => {
          if (res) {            
            this.setButtonColor(res?.designParam?.headerColor);          
            if(res?.designParam?.appLogo) this.logImage = this.assetUrl+res.designParam.appLogo;
          }
        })
      }

    setButtonColor(background: string) {
        const style = document.createElement('style');
        style.innerHTML = `  
    .btn-primary {
            background-color: ${background || '#0030A9'};
         }
            `;   
    
    
        document.head.appendChild(style);
      }   

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
