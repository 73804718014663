import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from '@app/core/service/global.service';

@Injectable()
export class ClassEditGuard implements CanActivate {
    constructor(public router: Router, private location: Location) { }
    canActivate() {
        const permissions = GlobalService.getPermission('Class');
        if (permissions['edit']) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}