import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'terms',
    templateUrl: 'terms.component.html'
})

export class TermsComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}