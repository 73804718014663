import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../service/storage.service';

@Injectable()
export class BodyInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Modify the request body here
        const userRole = JSON.parse(StorageService.getItem('role'));
        const companyId = StorageService.getItem('companyId');
        const parentIdExits = StorageService.getItem('parentId');

        console.log(userRole?.name , userRole?.name !== 'superadmin' , parentIdExits , companyId)
        if (userRole?.name && userRole?.name !== 'superadmin' && parentIdExits) {
            if (request.method.toLowerCase() === 'post' || request.method.toLowerCase() === 'put') {
                if (request.body instanceof FormData) {
                    const form = request.body;
                    form.append('companyId', companyId);
                    request = request.clone({
                        body: form
                    });
                } else {
                    const foo = {};
                    foo['companyId'] = companyId;
                    request = request.clone({
                        body: { ...request.body, ...foo }
                    });
                }
            } else {

                // Get the existing query parameters or create a new instance
                let params = request.params || new HttpParams();

                // Add or append query parameters based on your requirements
                params = params.append('companyId', companyId);
                // params = params.append('param2', 'value2');

                // Clone the request and set the modified parameters
                request = request.clone({
                    params
                });

            }
        }
        return next.handle(request);
    }
}

