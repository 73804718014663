import { ClassViewGuard } from './guard/groups/class/class-view.gaurd';
import { ClassListGuard } from './guard/groups/class/class-list.gaurd';
import { ClassEditGuard } from './guard/groups/class/class-edit.gaurd';
import { UserViewGuard } from './guard/groups/user/user-view.gaurd';
import { UserCreateGuard } from './guard/groups/user/user-create.gaurd';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from './guard/auth.guard';
import { NoAuthGuard } from './guard/no-auth.guard';
import { throwIfAlreadyLoaded } from './guard/module-import.guard';
import { SetHeaderInterceptor } from './interceptor/set-header.interceptor';
import { IPInterceptor } from './interceptor/ip.interceptor';
import { RetryInterceptor } from './interceptor/error-handler.interceptor';
import { FormatInterceptor } from './interceptor/format.interceptor';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { EditPageGuard } from './guard/edit-page.gaurd';
import { BeginTrainingGuard } from './guard/begin-training.gaurd';
import { TrainingTaskGuard } from './guard/training-task.gaurd';
import { AddTrainingTaskGuard } from './guard/add-training-task.gaurd';
import { ListTrainingTaskGuard } from './guard/list-training-task.gaurd';
import { EditTrainingTaskGuard } from './guard/edit-training-task.gaurd';
import { AddTaskGuard } from './guard/task-create.gaurd';
import { EditTaskGuard } from './guard/task-edit.gaurd';
import { AssessmentGuard } from './guard/assessment.gaurd';
import { UserListGuard } from './guard/groups/user/user-list.gaurd';
import { UserEditGuard } from './guard/groups/user/user-edit.gaurd';
import { ClassCreateGuard } from './guard/groups/class/class-create.gaurd';
import { SchoolCreateGuard } from './guard/groups/school/school-create.gaurd';
import { SchoolEditGuard } from './guard/groups/school/school-edit.gaurd';
import { SchoolListGuard } from './guard/groups/school/school-list.gaurd';
import { SchoolViewGuard } from './guard/groups/school/school-view.gaurd';
import { RegionCreateGuard } from './guard/groups/region/region-create.gaurd';
import { RegionEditGuard } from './guard/groups/region/region-edit.gaurd';
import { RegionListGuard } from './guard/groups/region/region-list.gaurd';
import { RegionViewGuard } from './guard/groups/region/region-view.gaurd';
import { CountryCreateGuard } from './guard/groups/country/country-create.gaurd';
import { CountryEditGuard } from './guard/groups/country/country-edit.gaurd';
import { CountryListGuard } from './guard/groups/country/country-list.gaurd';
import { CountryViewGuard } from './guard/groups/country/country-view.gaurd';
import { CacheInterceptor } from './interceptor/cache.interceptor';
import { CompanyGuard } from './guard/company.gaurd';
import { BodyInterceptor } from './interceptor/body.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthGuard,
    NoAuthGuard,
    EditPageGuard,
    BeginTrainingGuard,
    AssessmentGuard,
    TrainingTaskGuard,
    AddTrainingTaskGuard,
    ListTrainingTaskGuard,
    EditTrainingTaskGuard,
    AddTaskGuard,
    EditTaskGuard,
    UserListGuard,
    UserCreateGuard,
    UserViewGuard,
    UserEditGuard,
    ClassCreateGuard,
    ClassEditGuard,
    ClassListGuard,
    ClassViewGuard,
    SchoolCreateGuard,
    SchoolEditGuard,
    SchoolListGuard,
    SchoolViewGuard,
    RegionCreateGuard,
    RegionEditGuard,
    RegionListGuard,
    RegionViewGuard,
    CountryCreateGuard,
    CountryEditGuard,
    CountryListGuard,
    CountryViewGuard,
    CompanyGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IPInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CacheInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FormatInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BodyInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
