import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../service/storage.service';

@Injectable()
export class CompanyGuard implements CanActivate {
    constructor(public router: Router, private location: Location) { }
    canActivate() {
        const permissions = JSON.parse(StorageService.getItem('role') || '{}');
        if (permissions['name'] === 'superadmin') {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}