import { Injectable } from '@angular/core';
import { emailRegex } from '../constants/regex';
import { StorageService } from './storage.service';
import { ToastrCustomService } from './toastr.service';
/**
 * @author Dolly Garg <dolly.garg@jploft.in>
 */
@Injectable({providedIn: 'root'})
export class GlobalService {
    constructor(public toastr: ToastrCustomService) { }
    /**
     * 
     * @param object 
     * @returns 
     */
    static emptyObjectWithKeys(object): boolean {
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                const element = object[key];
                console.log(key, element);
                if (element === null || element !== '' || typeof element !== 'undefined') return true;
            }
        }
        return false;
    }

    /**
     * 
     * @param object 
     * @returns 
     */
    static emptyObject(object): boolean {
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                const element = object[key];
                if (element === null || element === '' || typeof element === 'undefined') return true;
            }
        }
        return false;
    }
    /**
     * 
     * @param val 
     * @returns 
     */
    static checkForEmpty(val) {
        if (val !== null && val !== '' &&  typeof val !== 'undefined') return false;
        return true;
    }

    /**
     * 
     * @param formgroup_ 
     * @param mandatoryKeys 
     * @returns 
     */
    static isValidRow(formgroup_, mandatoryKeys): boolean {
        const formControls_ = formgroup_['controls'];
        for (let i = 0; i < mandatoryKeys.length; i++) {
          const key = mandatoryKeys[i];
          if (GlobalService.checkForEmpty(formControls_[key]['value'])) return false;
        }
        return true;
    }
    
    /**
     * 
     * @param formGroups_ 
     * @param mandatoryKeys 
     * @returns 
     */
    static isValidFormArray(formGroups_, mandatoryKeys): boolean {
        for (let i = 0; i < formGroups_.length; i++) {
          const formgroup_ = formGroups_[i];
          if (!GlobalService.isValidRow(formgroup_, mandatoryKeys)) return false;
        }
        return true;
    }

    /**
     * 
     * @param permissions 
     */
    static setPermissions(permissions) {
        permissions.forEach(module => {
            StorageService.setItem(module.module, JSON.stringify(module.permissions));
        });
    }

    /**
     * 
     * @param module 
     * @returns 
     */
    static getPermission(module) {
        const permissions = JSON.parse(StorageService.getItem(module));
        let permissions_ = {};
        permissions.forEach(permission => {
            permissions_[permission['name']] = permission['value'];
        });
        return permissions_;
    }

    /**
     * 
     * @param dateObj 
     * @returns 
     */
    static getDate(dateObj: Object) {
        if (dateObj.hasOwnProperty('day') && dateObj.hasOwnProperty('month') && dateObj.hasOwnProperty('year')) {
            const d = new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day'], 0, 0, 0, 0);
            return d.toUTCString();
        } else {
            return dateObj;
        }
    }

    // static getProjectDateFormat(dateObj) {
    //     if (dateObj.hasOwnProperty('day') && dateObj.hasOwnProperty('month') && dateObj.hasOwnProperty('year')) {
    //         dateObj['month'] = '' + dateObj['month'];
    //         dateObj['day'] = '' + dateObj['day'];
    //         return (dateObj['day'].length == 1 ? '0' + dateObj['day'] : dateObj['day']) + '-' + 
    //         (dateObj['month'].length == 1 ? '0' + dateObj['month'] : dateObj['month']) + '-' + 
    //         dateObj['year']
    //     } else {
    //         return dateObj;
    //     }
    // }

    static formatDateAMPM(date) {
        date = new Date(date);
        const _date = GlobalService.getDateFromDate(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return _date + ' ' + strTime;
    }

    static getProjectDateFormat1(year, month, day, hour, minute) {
        return (new Date(year, month - 1, day, hour, minute, 0, 0)).toUTCString();
    }

    static getProjectDateFormat(dateObj) {
        if (dateObj.hasOwnProperty('day') && 
            dateObj.hasOwnProperty('month') && 
            dateObj.hasOwnProperty('year')) {
            return (new Date(dateObj['year'], dateObj['month'] - 1, dateObj['day'], 0, 0, 0, 0)).toUTCString();
        } else {
            return dateObj;
        }
    }

    static getProjectDateFormatFromDate(date: string) {
        if (date !== null && typeof date !== 'undefined' && date !== '') {
            const date_ = date.split('-');
            return (new Date(+date_[2], +date_[1] - 1, +date_[0], 0, 0, 0, 0)).toUTCString();
        } else {
            return '';
        }
    }

    static compareDate(date: string) {
        if (date !== null && typeof date !== 'undefined' && date !== '') {
            const date_ = date.split('-');
            return (new Date(+date_[2], +date_[1] - 1, +date_[0], 0, 0, 0, 0)).getTime() >= (new Date()).getTime();
        } else {
            return '';
        }
    }


    static getDateForDatePicker(date) {
        if (date !== null && typeof date !== 'undefined' && date !== '') {
            const date_ = date.split('-');
            return {
                year: +date_[2],
                month: +(('' + date_[1]).length === 1 ? ('0' + date_[1]) : date_[1]),
                day: +(('' + date_[0]).length === 1 ? ('0' + date_[0]) : date_[0]),
            }
        } else {
            return '';
        }
    }

    static getDateFromDate(date: Date) {
        const dateObj = {
            year: date.getFullYear(),
            month: '' + (date.getMonth() + 1),
            day: '' + (date.getDate())
        }
        return (dateObj['day'].length == 1 ? '0' + dateObj['day'] : dateObj['day']) + '-' + 
            (dateObj['month'].length == 1 ? '0' + dateObj['month'] : dateObj['month']) + '-' + 
            dateObj['year'];
    }

    static getDateFromDateObjectForPicker(date: Date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        }
    }

    static checkForValidFormat(date) {
        if (date !== null && typeof date !== 'undefined' && date !== '') {

        } else {
            return false;
        }
    }

    // static isValidDate(dateString) {
    //     var regEx = /^\d{4}-\d{1,2}-\d{1,2}$/;
    //     if(!dateString.match(regEx)) return false;  // Invalid format
    //     var d = new Date(dateString);
    //     var dNum = d.getTime();
    //     if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
    //     return d.toISOString().slice(0,10) === dateString;
    // }

    static isValidDate(dateString) {
        // First check for the pattern !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)
        const regEx = /^\d{1,2}-\d{1,2}-\d{4}$/;
        if(!regEx.test(dateString))
            return false;

        // Parse the date parts to integers
        var parts = dateString.split("-");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };

    static getDateFromFormat(date) {
        const date_ = date.split('-');
        return (date_['0'].length == 1 ? '0' + date_['0'] : date_['0']) + '-' + 
        (date_['1'].length == 1 ? '0' + date_['1'] : date_['1']) + '-' + 
        date_['2']
    }

    static isdateToday(date1) {
        let date2 = new Date();
        const date1_ = date1.split('-');
        const d = new Date(+date1_[2], +date1_[1] - 1, +date1_[0], 0, 0, 0, 0);
        if (+date1_[2] <= date2.getFullYear() && 
            (+date1_[1] - 1) <= date2.getMonth() && 
            date2.getDate() >= +date1_[0]) {
            return true;
        }
        return false;
    }

    static validateName(val) {
        var regex = /^[a-zA-Z ]{2,30}$/;
        return regex.test(val);
    }

    static validateEmail(val) {
        return emailRegex.test(val);
    }
}