import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from '../service/global.service';

@Injectable()
export class AddTrainingTaskGuard implements CanActivate {
    constructor(public router: Router, private location: Location) { }
    canActivate(): boolean {
        const permissions = GlobalService.getPermission('Training Task');
        if (permissions['create']) {
            return true;
        } else {
            this.location.back();
            return false;
        }
    }
}