<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand">
    <img height="40" [src]="logImage">
  </a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li *ngFor="let item of navItems" class="nav-item">
        <a class="nav-link">{{item.title}}</a>
      </li>
    </ul>
  </div>
</nav>
<div class="main-d dashboard permision sys-app-details">
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12 mt-4 text-center">
        <img *ngIf="!about?.video" class="img-fluid" [src]="about?.fileUrl" />
        <video *ngIf="about?.video" class="img-fluid" [src]="about?.fileUrl" controls></video>
      </div>
    </div>
    <div class="row m-5 py-5 text-center">
      <div class="col-md-12"><img class="img-fluid abt-logo" [src]="logImage" />
      </div>
    </div>
    <h1 class="text-center">{{about?.headingLine1}}</h1>
    <h3 class="h3-heding text-center">{{about?.headingLine2}}</h3>
    <div class="text-center mt-5 h4" [innerHtml]="about?.bodyText"></div>

    <div class="row my-5" *ngIf="about">
      <div class="col-md-12 text-center">
        <button class="main-btn-cncel text-center">{{about?.primaryLable}}</button>
        <button class="disable-btn text-center">{{about?.secondryLable}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-body modal-dialog">
    <div class="myform form">
      <div class="logo mb-3">
        <img class="mx-auto d-block mb-4" [src]="logImage">
        <div class="col-md-12 p-0 text-center">
          <h1>Log in</h1>
        </div>
      </div>
      <div [hidden]="!error" class="alert alert-danger">
        {{ error }}
      </div>
      <form (ngSubmit)="login()" [formGroup]="loginForm">
        <div class="form-group">
          <label>Email</label>
          <input type="email" name="email" class="form-control" formControlName="email">
          <app-control-messages [control]="f['email']"></app-control-messages>
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" name="password" class="form-control" formControlName="password">
          <app-control-messages [control]="f['password']"></app-control-messages>
        </div>
        <div class="col-md-12 p-0 mt-4 text-center">
          <button type="submit" class="btn btn-block mybtn"
            [ngClass]="loginForm.invalid && loginForm.touched ? 'disable-btn' : 'btn-primary'"
            [disabled]="loginForm.invalid && loginForm.touched" (click)="login()">
            <span>LOG IN</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-body modal-dialog">
    <div class="myform form pb-4">
      <div class="logo mb-3">
        <img class="mx-auto d-block mb-4" [src]="logImage">
        <div class="col-md-12 p-0 text-center">
          <h1>CREATE YOUR ACCOUNT
            TO BEGIN TRAINING</h1>
        </div>
      </div>
      <ng-container>
        <form (ngSubmit)="submit()" [formGroup]="myForm">
          <div class="form-group">
            <label>Name</label>
            <input type="text" name="namett" class="form-control" formControlName="name" readonly>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input type="text" name="emaittl" class="form-control" formControlName="email" readonly>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" name="fffpassword" class="form-control" formControlName="password">
            <app-control-messages [control]="fR['password']"></app-control-messages>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" 
              (change)="accept= +$event.target.value" [checked]="+accept === 1"
              value="1" >
            <label class="form-check-label" for="exampleCheck1">Accept&nbsp;
            </label><a (click)="modalOpen(3)" >Terms of Use</a>
          </div>
          <div class="col-md-12 p-0 mt-4 text-center" *ngIf="accept === 1">
            <button type="submit" 
              class="btn btn-block mybtn btn-primary">CREATE ACCOUNT</button>
          </div>
          <div class="form-group mb-0">
            <p class="text-center alrdy-act">Already have an account? 
              <a (click)="redirectTo(2)">Sign In here!</a></p>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" id="termsAndConditionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-body modal-dialog">
    <div class="myform form pb-4">
      <div class="logo mb-3">
        <img class="mx-auto d-block mb-4" [src]="logImage">
        <div class="col-md-12 p-0 text-center">
          <h1>TERMS OF USE</h1>
        </div>
      </div>
      <div class="modal-body">
        <p>Some text in the modal.</p>
      </div>
      <div class="col-md-12 p-0 mt-4 text-center agre-dgre">
        <button type="button" class="col-md-6 btn btn-block mybtn btn-primary" 
          (click)="accept=0;closeModal(3);" >DISAGREE</button>
        <button type="button" class="col-md-6 btn btn-block mybtn btn-primary" 
          (click)="accept=1;closeModal(3)" >AGREE</button>
      </div>
      <div class="form-group mb-0">
        <p class="text-center alrdy-act">View Full Terms of Use <a routerLink="/index/terms" target="_blank" >Here</a></p>
      </div>
    </div>
  </div>
</div>
