import { Component, OnInit } from '@angular/core';
import { StorageService } from '@app/core/service/storage.service';
import { ThemeService } from '@core/service/theme.service';
import { Router } from '@angular/router';
import { CoreService } from '@app/core/service/core.service';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/core/service/auth.service';
import * as _ from 'lodash';
import { NAVITEMS, NAVITEMSFORPRAC } from '@app/core/constants/static';
import { environment } from '@env';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  assetUrl= environment.imageUrl;
  logImage:string="../../../assets/images/logoBlackW.png";



  isIndexPage: boolean;
  navItems;

  langItems = ["English"];
  isSuperAdmin = true;
  sub: Subscription;
  name = '';
  theamConfig: any;
  constructor(
    private router: Router,
    private themeService: ThemeService,
    private coreService: CoreService,
    private auth: AuthService) {
    const role = JSON.parse(StorageService.getItem('role'));
    const isPrac = (role['name'].includes('practitioner')) ? true : false;
    this.navItems = isPrac ? NAVITEMSFORPRAC : NAVITEMS;
    this.isSuperAdmin = role.name === 'superadmin' ? true : false;
    this.sub = this.coreService.emitIndexSub$.subscribe((val) => {
      if (val) {
        this.isIndexPage = false;
      } else {
        this.isIndexPage = true;
      }
    });
    this.setTheamConfig();
  } 

  setTheamConfig() {
    this.sub = this.themeService.globalConfig.subscribe((res: any) => {
      if (res) {
        this.theamConfig = res;
        let newNav: any = this.navItems.filter((ele: any) => {
          if (ele.value === 'ABOUT') {
            ele.title = this.theamConfig?.designParam?.aboutText || ele.title;
            if (this.theamConfig?.designParam?.aboutStatus !== '1') return;
          }
          if (ele.value === 'TRAINING' || ele.title === 'SELF ASSESS') {
            ele.title = this.theamConfig?.designParam?.trainingText || ele.title;
            if (this.theamConfig?.designParam?.trainingStatus !== '1') return;
          }
          if (ele.value === 'GROUPS' || ele.title === 'REGISTER') {
            ele.title = this.theamConfig?.designParam?.groupsText || ele.title;
            if (this.theamConfig?.designParam?.groupsStatus !== '1') return;
          }
          if (ele.value === 'PLAY IT') {
            ele.title = this.theamConfig?.designParam?.playitText || ele.title;
            if (this.theamConfig?.designParam?.playitStatus !== '1') return;
          }
          if (ele.value === 'ASSESS IT') {
            ele.title = this.theamConfig?.designParam?.assessitText || ele.title;
            if (this.theamConfig?.designParam?.assessitStatus !== '1') return;
          }
          if (ele.value === 'ANALYSE IT') {
            ele.title = this.theamConfig?.designParam?.analyseitText || ele.title;
            if (this.theamConfig?.designParam?.analyseitStatus !== '1') return;
          }

          return ele;

        })

        // let parsString: any = JSON.stringify(newNav);
        this.navItems = newNav;
        // this.navItems = JSON.parse(parsString);


        this.setButtonColor(this.theamConfig?.designParam?.headerColor);
        this.setBannerBGImage(this.theamConfig?.designParam?.banner);
        if(this.theamConfig?.designParam?.appLogo) this.logImage = this.assetUrl+this.theamConfig.designParam.appLogo;
      }
    })
  }

  setButtonColor(background: string) {
    const style = document.createElement('style');
    style.innerHTML = `
    .main-btn {
      background: ${background || '#0030A9'};
      color: #fff;
      font-size: 18px;
      padding: 10px 30px;
      font-weight: 500;
      width: 260px;
      border: 2px solid  ${background || '#0030A9'};
      display: inline-block;
      margin: 15px 15px 30px;
  }

  .main-btn:hover {
    background: #fff;
    color: ${background || '#0030A9'};
    border: 2px solid ${background || '#0030A9'};
    text-decoration: none;
}

  .main-btn-cncel:hover {
    background:  ${background || '#0030A9'};
    color: #fff;
    border: 2px solid  ${background || '#0030A9'};
    text-decoration: none;
}

.main-btn-cncel {
  background: #fff;
  color: ${background || '#0030A9'};
  font-size: 18px;
  padding: 10px 30px;
  font-weight: 500;
  border: 2px solid ${background || '#0030A9'};
  width: 270px;
  display: inline-block;
  margin: 15px 15px 30px;
}

.das-menu a {
  color: ${background || '#1444BD'};
}

// .serch-sec .form-control {
//   border-color:  ${background || '#0030A9'};
// }

.serch-sec a {
  font-size: 14px;
  color:  ${background || '#0030A9'};
}

.icon-block {
  background:  ${background || '#0030A9'};
  width: 125px;
  height: 125px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 1rem;
  color: white;
  font-size: 3.4rem;
  box-shadow: 0px 0px 1px 2 px;
}

.data-sta span {
  color:  ${background || '#69BFC5'};
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: ${background || '#0030A9'};
  border-color:${background || '#0030A9'}
}

.ddinfo1 { 
  color: ${background || '#117ae4'} !important;
}

a:hover {
  color: ${background || '#0056b3'};
}
        `;



    document.head.appendChild(style);
  }


  setBannerBGImage(bannerImage:string){
    if(bannerImage){
      const style = document.createElement('style');
    style.innerHTML = `

    .hero-image {
      background: url(${this.assetUrl}${bannerImage});
      background-repeat: no-repeat;
  background-size: contain;
  margin-top: 66px;
  background-position: top;
    }

    `;

    console.log(style)
    document.head.appendChild(style);
    }
  }

  emitIndex(val) {
    this.coreService.emitIndex(val);
  }

  ngOnInit() {
    this.isIndexPage = this.router.url === '/dashboard' ? true : false;
    this.name = JSON.parse(StorageService.getItem('username')) || '';
  }

  logout(): void {
    this.auth.logout();
  }

  selectLang(event): void {
    // console.log(event.target.value);
  }

  toggleTheme(checked: boolean) {
    this.themeService.setDarkTheme(checked);
  }
}
