import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiConstant } from '../constants/api';
import { HttpClientService } from './http-client.service';

@Injectable({ providedIn: 'root' })
export class PageService {
  constructor(private http: HttpClientService) { }

  async add(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${ApiConstant.PAGE}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }

  async update(id, payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`${ApiConstant.PAGE}/${id}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }

  async getSingle(title): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${ApiConstant.PAGE}/${title}`)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }
}