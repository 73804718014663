<div class="myform form">
  <div class="logo mb-3">
    <img class="mx-auto d-block mb-4 log-image" [src]="logImage">
    <div class="col-md-12 p-0 text-center">
      <h1>Log in</h1>
    </div>
  </div>
  <div [hidden]="!error" class="alert alert-danger">
    {{ error }}
  </div>
  <!-- <fieldset [disabled]="isLoading">
    
  </fieldset> -->
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div class="form-group">
      <label>Email</label>
      <input type="email" name="email" class="form-control" formControlName="email">
      <app-control-messages [control]="f['email']"></app-control-messages>
    </div>
    <div class="form-group">
      <label>Password</label>
      <input type="password" name="password" class="form-control" formControlName="password">
      <app-control-messages [control]="f['password']"></app-control-messages>
    </div>

    <div class="col-md-12 text-right">
      <p> <a class="font-weight-bold" href="javascript:;" [routerLink]="['/forgot-password']">Forgot Password?</a> </p>
    </div>

    <div class="col-md-12 p-0 mt-4 text-center">
      <button type="submit" class="btn btn-block mybtn btn-primary"
         (click)="login()">
        <span>LOG IN</span>
        <!-- [ngClass]="loginForm.invalid && loginForm.touched ? 'disable-btn' : 'btn-primary'"
        [disabled]="loginForm.invalid && loginForm.touched" -->
        <!-- [hidden]="isLoading" <span [hidden]="!isLoading">
          <fa-icon icon="spinner" size="2x" [spin]="true"></fa-icon>
        </span> -->
      </button>
    </div>
    <!-- <div class="form-group mb-0">
      <p class="text-center">Don't have an account? <a [routerLink]="['/auth/register']">Sign up here!</a></p>
    </div> -->

    <!--<div class="form-group"> [disabled]="loginForm.invalid" 
      <p class="text-center"><a href="forgot-password.html">Forgot Password</a></p>
    </div>-->
  </form>
</div>