import { Injectable } from '@angular/core';
import { throwError, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { ApiConstant } from '../constants/api';
import { HttpClientService } from './http-client.service';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { HostProviderService } from './hostprovider.service';
/**
 * @author @author Dolly Garg <dolly.garg@jploft.in>
 */
interface LoginContextInterface {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  nameSub = new Subject<string>();
  nameSub$ = this.nameSub.asObservable();
  constructor(
    private http: HttpClientService, 
    private router: Router,
    private hostProviderService: HostProviderService) {}

  async login(loginContext: LoginContextInterface): Promise<any> {
    return new Promise((resolve, reject) => {

      //need to discuss to make dynamic
      const hostName: string = this.hostProviderService.getHostname();

      loginContext['domainName']= hostName;
      this.http.post(ApiConstant.LOGIN, loginContext)
      .pipe(
        map((res:any) => {
          if (res?.result.token) {
            this.token = res.result.token;
            StorageService.setItem('token', res.result.token);

          // setting parent id for company Id send in payload request
           if(res?.result?.parentId){
            StorageService.setItem('parentId', res?.result?.parentId);
           };

            return this.getDecodedAccessToken(this.token);
          } else {
            throwError('Token is not generated');
          }
        }),
        map((_token) => {
          console.log('token',_token)
          const obj = {
            'userId': _token.uid,
            'username': _token.scope.name,
            'role': {_id: _token.scope.role._id, name: _token.scope.role.name}
          };


          this.setItemsStorage(obj);
          return obj;
        }))
        .subscribe((res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  async firstLogin(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(ApiConstant.FIRST_LOGIN, payload)
      .pipe(
        map((res:any) => {
          if (res.result.token) {
            this.token = res.result.token;
            StorageService.setItem('token', res.result.token);

             // setting parent id for company Id send in payload request
           if(res?.result?.parentId){
            StorageService.setItem('parentId', res?.result?.parentId);
           };
           
            return this.getDecodedAccessToken(this.token);
          } else {
            throwError('Token is not generated');
          }
        }),
        map((_token) => {
          const obj = {
            'userId': _token.uid,
            'username': _token.scope.name,
            'role': {_id: _token.scope.role._id, name: _token.scope.role.name}
          };
          this.setItemsStorage(obj);
          return obj;
        }))
        .subscribe((res) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  setItemsStorage(object) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];
        StorageService.setItem(key, JSON.stringify(element));
      }
    }
  }

  
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (error) {
      return error;
    }
  }

  logout() {
    StorageService.removeItem('token');
    const role = JSON.parse(StorageService.getItem('role'));
    if (role['name'] === 'practitioner') {
      this.router.navigate(['/index'], { queryParams: { page: 1 } });
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  static get isAuth(): boolean {
    if(this._token) {
      return true;
    }
    return false
  }

  static get _token(): string {
    return StorageService.getItem('token');
  }

  //FORGOT PASSWORD SECTION
  async sendOTPApi(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${ApiConstant.SEND_OTP}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }


  async verifyOTP(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${ApiConstant.VERIFY_OTP}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }

  async resetPassword(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${ApiConstant.RESET_PASSWORD}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          }))
        .subscribe((res) => {
          resolve(res);
        },
          (error) => {
            reject(error);
          }
        )
    });
  }

}
