import { Injectable, Inject } from '@angular/core';
import { WINDOW } from './../../window.providers';

@Injectable()
export class HostProviderService {

    constructor(@Inject(WINDOW) private window: Window) {
    }

    getHostname(): string {
        return this.window.location.hostname;
        // return 'yst.movementforsport.co.uk';
    }
}
