import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { ApiConstant } from '../constants/api';
import { StorageService } from './storage.service';
import { HostProviderService } from './hostprovider.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDarkTheme: BehaviorSubject<boolean>;
  public globalConfig = new BehaviorSubject(null)
  constructor(private http: HttpClientService, private hostProviderService: HostProviderService) {
    this.isDarkTheme = new BehaviorSubject<boolean>(
      localStorage.getItem('isDarkTheme') === 'true'
    );

    this.getTheamConfig();
  }

  setDarkTheme(isDarkTheme: boolean) {
    this.isDarkTheme.next(isDarkTheme);
    localStorage.setItem('isDarkTheme', this.isDarkTheme.value.toString());
  }

  getDarkTheme(): Observable<boolean> {
    return this.isDarkTheme;
  }

  getTheamConfig() {
    const hostName: string = this.hostProviderService.getHostname();
    console.log('this is you host name:- ',hostName);
    const paylaod: any = { domainName: hostName };
    this.showThemeLoader();
    this.http.post(ApiConstant.THEAM_CONFIG, paylaod).subscribe({
      next: (res: any) => {
        this.hideThemeLoader();
        if (res?.result) {
          this.globalConfig.next(res.result);

          // adding company Id to share in requests as company
          if (res?.result?._id) {
            StorageService.setItem('companyId', res.result._id);
          }
        }
      },
      error: (err: any) => {
        console.log('error in theam API', err);
        this.hideThemeLoader();
      }
    });
  }

  showThemeLoader() {
    const loader = document.getElementById('theme-loader');
    if (loader) {
      loader.style.display = 'block';
    }
  }

  hideThemeLoader() {
    setTimeout(() => {
      const loader = document.getElementById('theme-loader');
      if (loader) {
        loader.style.display = 'none';
      }
    }, 1500);
  }
}
