import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from '@core/service/auth.service';
import { validateEmail } from '@app/core/validators/email-validator';
import { NgxSpinnerService } from "ngx-spinner";
import { ThemeService } from '@app/core/service/theme.service';
import { environment } from '@env';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnDestroy {
  error: string = '';
  isLoading: boolean;
  loginForm: FormGroup;

  
  private sub = new Subscription();


  assetUrl= environment.imageUrl;
  logImage:string="../../../../../assets/images/logoBlack.png";

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private themeService:ThemeService
  ) {
    this.buildForm();
    this.setTheamConfig();
  }

  get f() {
    return this.loginForm.controls;
  }

  async login() {
    this.error = '';
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.spinner.show();
      this.isLoading = true;
      const credentials = this.loginForm.value;
      try {
        const user = await this.authService.login(credentials);
        // console.log(user);
        this.isLoading = false;
        this.themeService.getTheamConfig();
        this.router.navigate(['/dashboard']);
      } catch (error) {
        console.log(error);
        this.error = error.msg || '';
        this.spinner.hide();
      }
    }
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        validateEmail
      ]],
      password: ['', Validators.required]
    });
  }


     // setting theme 

     setTheamConfig() {
      this.sub = this.themeService.globalConfig.subscribe((res: any) => {
        if (res) {           
          this.setButtonColor(res?.designParam?.headerColor);          
          if(res?.designParam?.appLogo) this.logImage = this.assetUrl+res.designParam.appLogo;
        }
      })
    }

  setButtonColor(background: string) {
      const style = document.createElement('style');
      style.innerHTML = `  
  .btn-primary {
          background-color: ${background || '#0030A9'};
          border: 2px solid ${background || '#0030A9'};
       }

       .btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
        background: #fff;
        color: ${background || '#0030A9'};
        border: 2px solid ${background || '#0030A9'};
    }
          `; 
  
  
      document.head.appendChild(style);
    }  

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
