<div class="main-d dashboard loginn"> <!--firsttime for register-->
  <div class="container">
    <div class="row">
      <div class="mx-auto">
        <div id="first">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>