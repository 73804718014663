import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConstant } from '../constants/api';
import { GlobalService } from './global.service';
import { HttpClientService } from './http-client.service';

@Injectable({ providedIn: 'root' })
export class TrainingTaskService {
  constructor(private http: HttpClientService) {}
  getList(queryLimit: any, page: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${ApiConstant.TRAININGTASKS}?queryLimit=${queryLimit}&page=${page}`
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  add(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${ApiConstant.TRAININGTASKS}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  update(id, payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${ApiConstant.TRAININGTASKS}/${id}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getSingle(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${ApiConstant.TRAININGTASKS}/${id}`)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  setStatus(id) {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${ApiConstant.SETTRAININGSTATUS}/${id}`)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  mandatoryKeys = ['question', 'answer'];
  isValidFormArray(formGroups_): boolean {
    if (GlobalService.isValidFormArray(formGroups_, this.mandatoryKeys))
      return true;
    return false;
  }

  getCurrentTraining() {
    try {
      return new Promise((resolve, reject) => {
        this.http
          .get(`${ApiConstant.CURRENT_TRAINING}`)
          .pipe(
            map((res: any) => {
              return res.result;
            })
          )
          .subscribe(
            res => {
              resolve(res);
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (error) {}
  }

  getRandomTask() {
    try {
      return new Promise((resolve, reject) => {
        this.http
          .get(`${ApiConstant.GET_RANDOM_TASKS}`)
          .pipe(
            map((res: any) => {
              return res.result;
            })
          )
          .subscribe(
            res => {
              resolve(res);
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (error) {}
  }

  finshLater(): Observable<any> {
    return this.http.get(`${ApiConstant.GET_RANDOM_TASKS}?isFinishLater=true`);
  }

  submitTraining(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${ApiConstant.SAVE_TRAINING}`, payload)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  getTrainingAttemptsList(queryLimit, page) {
    try {
      return new Promise((resolve, reject) => {
        this.http
          .get(
            `${ApiConstant.TRAINING_ATTEMPTS_LIST}?queryLimit=${queryLimit}&page=${page}`
          )
          // .pipe(
          //     map((res: any) => {
          //         return res.result;
          //     }))
          .subscribe(
            res => {
              resolve(res);
            },
            error => {
              reject(error);
            }
          );
      });
    } catch (error) {}
  }

  getCurrentTrainingStatus(): Promise<boolean> {
    try {
      return new Promise((resolve, reject) => {
        this.getCurrentTraining()
          .then(response => {
            if (
              (response['status'] === 'Completed' &&
                response['rating'] === 'Reliable') ||
              (response['status'] === 'Completed' &&
                response['rating'] === 'Unreliable' &&
                response['attempt'] === 2)
            ) {
              resolve(true);
            } else if (response === null) {
              resolve(true);
            } else {
              resolve(true);
            }
          })
          .catch(error => {});
      });
    } catch (error) {}
  }

  getPracTrainingsAttempts(id) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${ApiConstant.RESULT_PRACTITITIONER}/${id}`)
        .pipe(
          map((res: any) => {
            return res.result;
          })
        )
        .subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
