export const NAVITEMS = [
    { tabId: 1, value:'ABOUT', link: '/dashboard/about', title: 'ABOUT', image: 'about.svg', icon:'fa-info' },
    { tabId: 2, value:'TRAINING', link: '/dashboard/training', title: 'TRAINING', image: 'training.svg', icon:'fa-television' },
    { tabId: 5, value:'GROUPS', link: '/dashboard/groups/users', title: 'GROUPS', image: 'groups.svg', icon:'fa-users' },
    { tabId: 3, value:'PLAY IT', link: '/dashboard/play-it', title: 'PLAY IT', image: 'tasks.svg' , icon:'fa-pencil-square-o' },
    { tabId: 4, value:'ASSESS IT', link: '/dashboard/assess-it', title: 'ASSESS IT', image: 'assess.svg', icon:'fa-pencil' },
];

export const NAVITEMSFORPRAC = [
    { tabId: 1, value:'ABOUT', link: '/dashboard/about', title: 'ABOUT', image: 'about.svg', icon:'fa-info' },
    { tabId: 2, value:'TRAINING', link: '/dashboard/training', title: 'TRAINING', image: 'training.svg', icon:'fa-television' },
    { tabId: 5, value:'GROUPS', link: '/dashboard/groups/classes', title: 'GROUPS', image: 'groups.svg', icon:'fa-users' },
    { tabId: 3, value:'PLAY IT', link: '/dashboard/play-it', title: 'PLAY IT', image: 'tasks.svg' , icon:'fa-pencil-square-o' },
    { tabId: 4, value:'ASSESS IT', link: '/dashboard/assess-it', title: 'ASSESS IT', image: 'assess.svg', icon:'fa-pencil' }
];

export const HOMEITEMS = [
    ...NAVITEMS,
    { value:'ANALYSE IT', link: '/dashboard/analyse-it', title: 'ANALYSE IT', image: 'analytics.svg', icon:'fa-bar-chart' }
];

export const HOMEITEMSFORPRAC = [
    ...NAVITEMSFORPRAC,
    { value:'ANALYSE IT', link: '/dashboard/analyse-it', title: 'ANALYSE IT', image: 'analytics.svg' , icon:'fa-bar-chart' }
];

export const MESSAGE = {
    'SAVED_SUCCESSFULLY': 'Saved Successfully',
    'UPDATED_SUCCESSFULLY': 'Updated Successfully',
    'DELETE_SUCCESSFULLY': 'Deleted Successfully',
    'TRAINING_WITHOUT_VIDEO': 'Training is Inactive, Please upload video to activate it',
    'INVALID_ENTRIES': "Couldn't Import complete file, Invalid Entries Found",
    'INVALID_ID': "Couldn't find data on the Id",
    "SUCCESSFULLY_DONE": "Successfully Done"
}

export const PAGE_TITLES = {
    'about': 'About',
    'play-it': 'Play It',
    'training': 'Training',
    'assess-it': 'Assess It'
}

export const ANSWER_OPTIONS = [1,2,3,4,5];
export const QUERY_LIMIT = 10;
export const DISABLEBORDER = 'disable-btn-cn';
export const DISABLEWHOLE = 'disable-btn';
export const MAINBTNWHOLE = 'main-btn';
export const MAINBTNBORDER = 'main-btn-cncel';

export const GENDER_LIST = [{name: 'Male', val: 'male'}, {name: 'Female', val: 'female'}];
export const STATUS_PRAC = {
    pending: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed'
}

export const TRAINING_SCREENS = {
    'begin' : {
        status: 'MY TRAINING STATUS: NOT STARTED',
        text: `Before you begin your training module, 
            make sure you have reviewed all the Tasks. 
            Three of the 14 Tasks will be randomly selected 
            for your assessment training.`
    },
    'inProgress': {
        status: 'MY TRAINING STATUS: IN PROGRESS',
        text: `Select 'Continue Training' below to complete your training module.`
    },
    'secondAttempt': {
        heading: 'SECOND ATTEMPT REQUIRED',
        text: "You have completed your first training attempt, but didn't achieve 80%. Please try again."
    },
    'completed': {
        status: 'MY TRAINING STATUS: COMPLETED',
        text: 'Your training is complete, no action is required at this time. Your next training assessment date is '
    },
    'success': {
        heading: 'CONGRATULATIONS!',
        text: "You've completed your Training and can begin Participant Assessments.",
        button1: 'MY GROUPS',
        button2: 'BEGIN ASSESSMENT'
    },
    'failure': {
        heading: 'MY TRAINING STATUS: ',
        text1: `You may retake the training at any time, 
            but are not required to take it again until `,
        text2: "Select 'Continue Training' if you wish to retake your training module."
    }
}
// Your Current training is Unreliable.
export const MIMETYPE_IMAGE = 'image';
export const MIMETYPE_VIDEO = 'video';
export const MIMETYPE_PDF = 'pdf';
export const AGE_OPTIONS = new Array(25);