import { Injectable } from "@angular/core";
import { 
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable} from "rxjs";
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  constructor(private route: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req.url.includes('getDomainData'))
    if(req.url.includes('getDomainData')){
      return next.handle(req);
    }
    if (!(this.route.url.includes('login') || this.route.url.includes('register') 
      || this.route.url.includes('auth') || this.route.url.includes('index') || 
      this.route.url.includes('forgot-password') || this.route.url.includes('reset-password')
      )) {
      if (AuthService._token && !req.headers.has(this.AUTH_HEADER)) {
        req =  req.clone({
          headers: req.headers.set(this.AUTH_HEADER, 'Bearer ' +  AuthService._token)
        })
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}