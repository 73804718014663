import { AbstractControl } from '@angular/forms';
import { emailRegex } from '../constants/regex';

export function validateEmail(control: AbstractControl) {
	if (control.value && emailRegex.test(control.value)) {
        return null;
	} else if (!control.value) {
		return null;
	} else {
		return { inValidEmail: true };
	}
}