import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { StorageService } from '../service/storage.service';

@Injectable()
export class IPInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('ip intercepted');
        if (req.method.toLowerCase() === 'post' || req.method.toLowerCase() === 'put') {
            if (req.body instanceof FormData) {
                req = req.clone({
                    body: req.body.append('ip', StorageService.getItem('ip'))
                });
            } else {
                const foo = {};
                foo['ip'] = StorageService.getItem('ip');
                req = req.clone({
                    body: { ...req.body, ...foo }
                });
            }
        }
        return next.handle(req);
    }
}