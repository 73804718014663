import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConstant } from '../constants/api';
import { HttpClientService } from './http-client.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class CoreService {
    emitIndexSub = new Subject<boolean>();
    emitIndexSub$ = this.emitIndexSub.asObservable();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClientService
    ) { }


    emitIndex(emit) {
        this.emitIndexSub.next(emit);
    }

    navigateToPath(path) {
        this.router.navigate([path]);
    }

    navigateToRelative(path) {
        this.router.navigate([path], { relativeTo: this.route });
    }

    getLanguageList() {
        return new Promise((resolve, reject) => {
            this.http.get(ApiConstant.LANGUAGES)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    resolve(res);
                }, (error) => {
                    reject(error);
                })
        });
    }

    getRolesList() {
        return new Promise((resolve, reject) => {
            this.http.get(ApiConstant.ROLES)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    StorageService.setItem('roles', JSON.stringify(res));
                    resolve(res);
                }, (error) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    getPhaseList() {
        return new Promise((resolve, reject) => {
            this.http.get(ApiConstant.PHASES)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    resolve(res);
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    })
        })
    }

    getMovementList() {
        return new Promise((resolve, reject) => {
            this.http.get(ApiConstant.MOVEMENTS)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    resolve(res);
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    })
        })
    }

    getTaskNumberList() {
        return new Promise((resolve, reject) => {
            this.http.get(ApiConstant.TASKNUMBER)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    resolve(res);
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    })
        })
    }

    getRolePermissions(roleId) {
        return new Promise((resolve, reject) => {
            this.http.get(`${ApiConstant.ROLE_PERMISSION}?page=1&queryLimit=50&role=${roleId}`)
                .pipe(
                    map(res => res.result))
                .subscribe(res => {
                    resolve(res);
                },
                    (error) => {
                        console.log(error);
                        reject(error);
                    })
        })
    }

    getFormObj(object) {
        let fd = new FormData();
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                const element = object[key];
                fd.append(key, element);
            }
        }
        return fd;
    }

    async dashboard(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(`${ApiConstant.DASHBAORD}`)
                .pipe(
                    map((res: any) => {
                        return res.result;
                    }))
                .subscribe((res) => {
                    resolve(res);
                },
                    (error) => {
                        reject(error);
                    }
                )
        });
    }

    getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        if (match !== null) {
            return (match && match[2].length === 11)
            ? match[2]
            : null;
        } else {
            return url;
        }
    }

    navigateToTask() {
        this.router.navigate(['/dashboard/play-it']);
    }
}