import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ToastrCustomService } from '../service/toastr.service';
import { AuthService } from '../service/auth.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrCustomService, private auth: AuthService) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0 && error.error instanceof ProgressEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.log('Client side error:', error.error)
          this.toastr.showError('Connection Timed out');
          // window.location.reload();
          return throwError(error.error);
        }
        if (error.status === 500) {
          const jwtMalformed = 'jwt malformed';
          const invalidToken = 'invalid token';
          const jwtExpired = 'jwt expired';
          if (error.error && error.error.data && error.error.data.message) {
            if (error.error.data.message.includes(jwtExpired) ||
              (error.error.data.message.includes(invalidToken)) ||
              error.error.data.message.includes(jwtMalformed)) {
              this.toastr.showError('Session Expired, Please Login Again');
              this.auth.logout();
              return throwError(error.error);
            }
          }
          this.toastr.showError('Server Error'); 
          return throwError(error.error);
        } else if (error.status === 400) {
          if(httpRequest.url.includes('getDomainData')){
            return next.handle(httpRequest);
          }
          if (error.error && error.error.length) {
            this.toastr.showError(error.error[0].msg);
            return throwError(error.error[0]);
          } else if (error.error.data.message) {
            this.toastr.showError(error.error.data.message);
            return throwError(error.error);
          }
        } else if (error.status === 403) {
          this.auth.logout();
          return throwError(error.error.data.message);
        } else if (error.status === 404) {
          return throwError(error.error.data.message);
        } else if (error.status === 401) {
          this.auth.logout();
          return throwError(error.error.data.message);
        } else {
          return throwError(error.error);
        }
      })
    );
  }
}